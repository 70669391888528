<template>
  <span
    class="rounded-full pointer-events-auto text-xs font-semibold flex items-center justify-between transition text-gray-400 hover:text-primary"
  >
    <QuestionCircleSolidIcon v-tooltip="tooltip" class="h-5 w-5" />
  </span>
</template>

<script>
import { QuestionCircleSolidIcon } from "@/components/icons"
export default {
  components: {
    QuestionCircleSolidIcon,
  },
  props: {
    tooltip: {
      type: [String, Object],
      required: true,
    },
  },
}
</script>
